import React from 'react';
import { useMyContext } from '../../ContextProvider';
import { Timeframe } from '../../types/interfaces';

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const { timeframeSettings, updateTimeframeSettings } = useMyContext();
  // update the timeframe setting for this specific component
  const handleClick = (timeframe: Timeframe) => {
    updateTimeframeSettings(title, timeframe);
  };

  return (

    <div className="toggle-container">
      <button
        // className={timeframeSettings[title] === 'week' ? 'selected' : ''}
        className={`toggle-button-${timeframeSettings[title] === 'week' ? 'active' : 'inactive'}`}

        onClick={() => handleClick('week')}
      >
        Week
        {timeframeSettings[title] === 'week' && <i className="fa-sharp fa-solid fa-caret-down"></i>}

      </button>
      <button
        className={`toggle-button-${timeframeSettings[title] === 'month' ? 'active' : 'inactive'}`}
        onClick={() => handleClick('month')}
      >
        Month
        {timeframeSettings[title] === 'month' && <i className="fa-sharp fa-solid fa-caret-down"></i>}

      </button>
      <button
        className={`toggle-button-${timeframeSettings[title] === '3month' ? 'active' : 'inactive'}`}
        onClick={() => handleClick('3month')}
      >
        3 Months
        {timeframeSettings[title] === '3month' && <i className="fa-sharp fa-solid fa-caret-down"></i>}

      </button>
    </div>

  );
};

export default Header;
