import React, { useState, useEffect, useMemo } from 'react';

interface TrendItem {
  chainName: string;
  days_mentioned: number;
  total_views: number;
  min_daily_views: number;
  max_daily_views: number;
  avg_daily_views: number;
  avg_token_count: number;
  trend_percentage: number;
}

export interface versusProps {
  gainers: TrendItem[];
  losers: TrendItem[];
}

const getTimestamps = () => {
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const currentTimestamp = Math.floor(Date.now() / 1000);
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const oneMonthAgoTimestamp = Math.floor(oneMonthAgo.getTime() / 1000);

  return {
    currentTimestamp,
    oneMonthAgoTimestamp,
    currentDate: formatDate(new Date()),
    oneMonthAgoDate: formatDate(oneMonthAgo),
  };
};


function Chains() {

  const [versus, setVersus] = useState<versusProps | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {

    const fetchVersus = async () => {
      setLoading(true);
      setError(null);

      const { currentDate, oneMonthAgoDate } = getTimestamps();

      try {

        const response = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/chain-view-trends?videoLimit=100`
            : `${process.env.REACT_APP_AWS_API}/api/chain-view-trends?videoLimit=100`,
        );
        

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // Sort the data before setting state
        const sortedData = {
          gainers: [...data.gainers].sort((a, b) => b.total_views - a.total_views),
          losers: [...data.losers].sort((a, b) => b.total_views - a.total_views)
        };
        setVersus(sortedData);

      } catch (error) {
        console.error('Error fetching versus data:', error);
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(String(error));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchVersus();
  }, []);

  const renderGainersList = React.useMemo(() => {
    if (!versus?.gainers || !versus?.losers) return null;

    const sortedGainers = [...versus.gainers].sort((a, b) => b.total_views - a.total_views);
    const colors = [
      'rgba(80, 184, 231, 0.84)',
      'rgba(80, 184, 231, 0.6)',
      'rgba(80, 184, 231, 0.36)',
      'rgba(80, 184, 231, 0.24)',
      'rgba(80, 184, 231, 0.12)',
      'rgba(80, 184, 231, 0.06)',
      'rgba(80, 184, 231, 0.03)',
    ];    
    const totalViews = sortedGainers.reduce((sum, item) => sum + item.total_views, 0);
    
    // Separate items into main segments and others (less than 10%)
    const mainSegments: TrendItem[] = [];
    const otherSegments: TrendItem[] = [];
    
    sortedGainers.forEach(item => {
      const viewPercentage = (item.total_views / totalViews) * 100;
      if (viewPercentage >= 10) {
        mainSegments.push(item);
      } else {
        otherSegments.push(item);
      }
    });

    // Create combined "Other" segment if needed
    if (otherSegments.length > 0) {
      const otherTotalViews = otherSegments.reduce((sum, item) => sum + item.total_views, 0);
      mainSegments.push({
        chainName: 'Other',
        total_views: otherTotalViews,
        trend_percentage: 0,
        days_mentioned: 0,
        min_daily_views: 0,
        max_daily_views: 0,
        avg_daily_views: 0,
        avg_token_count: 0
      });
    }

    return (
      <div className="bar-chart-container">
        <div className="percentage-bar">
          {mainSegments.map((item: TrendItem, index: number) => {
            const viewPercentage = (item.total_views / totalViews) * 100;
            const segmentWidth = `${viewPercentage.toFixed(2)}%`;
            const backgroundColor = colors[index % colors.length];

            return (
              <div
                key={index}
                className="bar-segment"
                style={{
                  width: segmentWidth,
                  backgroundColor,
                }}
              >
                <div className="segment-label">
                  {item.chainName}
                  <small>
                    {viewPercentage.toFixed(2)}% 
                    {item.chainName !== 'Other'}
                    {/* {item.chainName !== 'Other' && `(${item.trend_percentage.toFixed(2)}% trend)`} */}
                  </small>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [versus?.gainers, versus?.losers]);


  const renderLosersList = React.useMemo(() => {
    if (!versus?.gainers || !versus?.losers) return null;

    const sortedLosers = [...versus.losers].sort((a, b) => b.total_views - a.total_views);
    const colors = [
      'rgba(231, 80, 80, 0.84)',
      'rgba(231, 80, 80, 0.6)',
      'rgba(231, 80, 80, 0.36)',
      'rgba(231, 80, 80, 0.24)',
      'rgba(231, 80, 80, 0.12)',
      'rgba(231, 80, 80, 0.06)',
      'rgba(231, 80, 80, 0.03)',
    ];    
    const totalViews = sortedLosers.reduce((sum, item) => sum + item.total_views, 0);
    
    // Separate items into main segments and others (less than 10%)
    const mainSegments: TrendItem[] = [];
    const otherSegments: TrendItem[] = [];
    
    sortedLosers.forEach(item => {
      const viewPercentage = (item.total_views / totalViews) * 100;
      if (viewPercentage >= 10) {
        mainSegments.push(item);
      } else {
        otherSegments.push(item);
      }
    });

    // Create combined "Other" segment if needed
    if (otherSegments.length > 0) {
      const otherTotalViews = otherSegments.reduce((sum, item) => sum + item.total_views, 0);
      mainSegments.push({
        chainName: 'Other',
        total_views: otherTotalViews,
        trend_percentage: 0,
        days_mentioned: 0,
        min_daily_views: 0,
        max_daily_views: 0,
        avg_daily_views: 0,
        avg_token_count: 0
      });
    }

    return (
      <div className="bar-chart-container">
        <div className="percentage-bar" style={{ direction: 'rtl' }}>
          {mainSegments.map((item: TrendItem, index: number) => {
            const viewPercentage = (item.total_views / totalViews) * 100;
            const segmentWidth = `${viewPercentage.toFixed(2)}%`;
            const backgroundColor = colors[index % colors.length];

            return (
              <div
                key={index}
                className="bar-segment"
                style={{
                  width: segmentWidth,
                  backgroundColor,
                }}
              >
                <div className="segment-label" style={{ direction: 'ltr' }}>
                  {item.chainName}
                  <small>
                    {viewPercentage.toFixed(2)}% 
                    {item.chainName !== 'Other'}
                    {/* {item.chainName !== 'Other' && `(${item.trend_percentage.toFixed(2)}% trend)`} */}
                  </small>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [versus?.gainers, versus?.losers]);



  // Add loading state display
  if (!versus) {
    return null;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <>
      <div className='general-container'>


          {loading ?
            <div className="loading">
              <i className="fa-sharp-duotone fa-solid fa-spinner-third"></i>
            </div>
            :
            <>
              <div className="versus-container">

                <div className='versus-box-left'>
                  <div className='versus-box-left-bottom'>
                    {renderLosersList}
                  </div>
                </div>

                <div className='versus-box-right'>
                  <div className='versus-box-right-bottom'>
                    {renderGainersList}
                  </div>
                </div>

              </div>
            </>
          }
      </div>
    </>
  );
}

export default Chains;
