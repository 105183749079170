import React, { useEffect } from 'react';
import Title from '../../components/Title';
import Price from '../../components/Price';
import FeatureStat from '../../components/FeatureStat';
import ChartArea from '../../components/ChartArea';
import { ListProps } from '../../types/interfaces';

interface ChartDetailedInFullProps {
  listItem: ListProps | null;
  featuredStatName: string;
  y1Measuring?: string;
}

const ChartDetailedInFull: React.FC<ChartDetailedInFullProps> = ({
  listItem,
  featuredStatName,
  y1Measuring
}) => {
  useEffect(() => {
  }, [listItem, featuredStatName]);

  const processedLogo = listItem?.logo ? listItem.logo.replace('/thumb/', '/standard/') : listItem?.logo;


  return ( 
    <>
      <div className="featured-chart featured-chart-full-details">
        <div className="feature-stat">

          <div className="stat-container">
            <div className="stat-container-left">
              <Title
                label={listItem?.label || ''}
                effect={true}
                title={listItem?.title || ''}
                subtitle={listItem?.chainName || ''}
                logo={processedLogo || ''}
                titleTruncate={18}
                hero={true}
              />
            </div>

            {listItem?.stats[0].value ? (
              <ChartArea
                y1Measuring={y1Measuring || 'views'}
                height={84}
                width={0}
                data={listItem?.coords || []}
                y1Colour={'#50B8E7'}
                y2Colour={'#50B8E7'}
              />
            ) : listItem?.coords && (listItem.coords.length > 1) ? (
              <ChartArea
                y1Measuring={y1Measuring || 'views'}
                height={84}
                width={0}
                data={listItem?.coords || []}
                y1Colour={'#50B8E7'}
                y2Colour={'#50B8E7'}
              />
            ) : (
              <div className="chart-area-placeholder">NO DATA YET FOR THIS COIN</div>
            )}

          </div>


          <div className="stat-container">

            <div className="stat-container-left"> </div>
            
            <div className="stat-container-right">

              <Price
                label={'Current Coin Price'}
                price={listItem?.price || 0}
                currency={'USD'}
              />
             
              <FeatureStat
                listItem={listItem}
                value={listItem?.stats[0]?.value || 0}
                mentions={listItem?.stats[2]?.value || 0}
                valueChange={listItem?.percentChange}
                subtitle={featuredStatName}
                top={'3px'}
                align={'right'}
                disableMoreSuffix={true}
              />

            </div>

          </div>

        </div>

      </div>

    </>
  );
};

export default ChartDetailedInFull;