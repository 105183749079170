import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const env = process.env.NODE_ENV;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

import("react-gtm-module").then(({ default: TagManager }) => {
  if (process.env.REACT_APP_NODE_ENV === 'prod') {
    // TagManager.initialize({ gtmId: "G-T67CSN3F99" }); // GOOGLE ANALYTICS
    TagManager.initialize({ gtmId: "GTM-P9JQFTS5" }); // GOOGLE TAG MANAGER
  }
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
