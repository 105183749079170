import React from 'react';
import Header from '../Header';
import Rollup from '../Rollup';
import FullscreenCurtains from '../FullscreenCurtains';
import useFullscreenCurtains from '../FullscreenCurtains/useFullscreenCurtains';
import { ListProps } from '../../types/interfaces';
import Sentiment from '../../modules/Sentiment';

interface FeatureWithInteractiveListProps {
  loading?: boolean;
  premium?: boolean;
  title: string;
  listItems: ListProps[];
  selectedItem: ListProps | null;
  featuredStatName: string;
  featureBackground: boolean;
  FeaturedComponent?: React.ComponentType<{
    listItem: ListProps | null;
    featuredStatName: string;
    featureBackground: boolean;
    secondaryFeaturedStatName?: string;
    y1Measuring?: string;
  }>;
  setSelectedItem: (item: ListProps | null) => void;
  y1Measuring?: string;
  renderListItem: (item: ListProps, index: number) => JSX.Element;
  renderLeftDrawerContent?: () => JSX.Element;
  renderRightDrawerContent?: () => JSX.Element;
  toggleButton?: JSX.Element;
}


function FeatureWithInteractiveList({
  loading = false,
  premium = false,
  title,
  listItems,
  selectedItem,
  featuredStatName,
  FeaturedComponent,
  featureBackground,
  setSelectedItem,
  y1Measuring,
  renderListItem,
  renderLeftDrawerContent,
  renderRightDrawerContent,
  toggleButton,
}: FeatureWithInteractiveListProps) {
  const { state, toggleDrawer, closeAllDrawers, handleClose } = useFullscreenCurtains();





  return (
    <>
      {/* <Header title={title} /> */}
      <div className='general-container-hero'>


        <div>
          <div className="toggle-container">
            {!loading &&
              toggleButton
            }
          </div>
          <div className="general-data-hero">
            {loading ?
              <div className="loading">
                <i className="fa-sharp-duotone fa-solid fa-spinner-third"></i>
              </div>
              :
              <>
                {FeaturedComponent && (
                  <FeaturedComponent
                    listItem={selectedItem}
                    featureBackground={featureBackground}
                    featuredStatName={featuredStatName}
                    y1Measuring={y1Measuring}
                  />
                )}

              </>
            }


          </div>
        </div>

        <div>

          <div className="toggle-container"></div>
          {/* <div className="toggle-container">{`MORE ${title}`}</div> */}
          <div className="list-module">
            {loading ?
              <div className="loading">
                <i className="fa-sharp-duotone fa-solid fa-spinner-third"></i>
              </div>
              :
              <>
                {listItems.length > 4 && <div className="list-module-scroll-indicator">
                  <i className="fa-sharp fa-regular fa-arrow-up"></i>
                </div>
                }
                {listItems.map(renderListItem)}
                {listItems.length > 4 && <div className="list-module-scroll-indicator-bottom">
                  <i className="fa-sharp fa-regular fa-arrow-down"></i>
                </div>
                }
              </>
            }
          </div>
        </div>

      </div>

      {/* START: Drawers */}
      {premium && selectedItem && (
        <FullscreenCurtains
          header={title}
          state={state}
          handleClose={handleClose}
          closeAllDrawers={closeAllDrawers}
          selectedItem={selectedItem}
        >
          {renderLeftDrawerContent && renderLeftDrawerContent()}
          {renderRightDrawerContent && renderRightDrawerContent()}
        </FullscreenCurtains>
      )}
      {/* END: Drawers */}
    </>
  );
}

export default FeatureWithInteractiveList;