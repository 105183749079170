import { useState, useEffect } from 'react';
import ChartAreaSelect from '../../components/ChartAreaSelect';
import { Select, MenuItem, FormControl, ListSubheader, InputLabel } from '@mui/material';

export interface CategorySwitchProps {
  averageConvictionPercentage: number;
  percentageChange: number;
  positiveConvictionPercentage: number;
  negativeConvictionPercentage: number;
  mixedConvictionPercentage: number;
  cryptoProjects: string[];
  projectName: string;
  tokenSymbol: string;
  chainName: string;
  logo: string;
  total_mentions: number;
  total_weighted_view_score: number;
  channelCount: number;
  total_views: number;
  days_mentioned: number;
  marketCapRank: number | null;
  sentimentUpPercentage: number;
  created: string;
  lastUpdated: string;
  categories: string;
}

interface CategoryData {
  category: string;
  totalScore: number;
  daysActive: number;
  maxUniqueTokens: number;
  timeSeries: Array<{ date: string; score: number }>;
}

// Update the interface to match new API response
interface CategoryTrend {
  category: string;
  trend_percentage: number;
  avg_daily_score: number;
  avg_unique_tokens: number;
  total_score: number;
  days_active: number;
  time_series?: Array<{ date: string; score: number }>;
}

interface CategoryTrendsData {
  gainers: CategoryTrend[];
  losers: CategoryTrend[];
}

// Update the sample data to match the expected format

// Add this interface to match ChartAreaSelect's expected data format
interface ChartDataPoint {
  date: string;
  score: number;
  tokens: number;
}

function CategorySwitch({ title = 'CategorySwitch' }: { title?: string }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [categorySwitchData, setCategorySwitchData] = useState<ChartDataPoint[] | null>(null);
  const [count, setCount] = useState(0);
  const [category, setCategory] = useState<string>('Select a Trending Category');
  const [categories, setCategories] = useState<string[]>([]);
  const [categoryTrends, setCategoryTrends] = useState<CategoryTrendsData | null>(null);
  const [selectedItem, setSelectedItem] = useState<CategorySwitchProps | null>(null);
  const [currentChartData, setCurrentChartData] = useState<Array<{ date: string; value: number }>>([]);
  const [chartData, setChartData] = useState<ChartDataPoint[]>([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_USE_REMOTE_API === 'true'
          ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/category-trends?videoLimit=300`
          : `${process.env.REACT_APP_AWS_API}/api/category-trends?videoLimit=300`,
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const CategoryData: CategoryTrendsData = await response.json();

      // Combine gainers and losers categories
      const allCategories = [
        ...CategoryData.gainers.map(item => item.category),
        ...CategoryData.losers.map(item => item.category)
      ];

      setCategories(allCategories);
      setCategoryTrends(CategoryData);


      // Set initial category and chart data if none selected
      if (!category && allCategories.length > 0) {
        setCategory(allCategories[0]);
        const initialCategory = CategoryData.gainers.find(item => item.category === allCategories[0])
          || CategoryData.losers.find(item => item.category === allCategories[0]);
        if (initialCategory?.time_series) {
          setCurrentChartData(initialCategory.time_series.map(point => ({
            date: point.date,
            value: point.score
          })));
        }
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError(true);
    }
  };

  useEffect(() => {
    fetchTopTokens();

    const interval = setInterval(() => {
      fetchTopTokens();
    }, 30000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [category]); // Add category as dependency

  // Move fetchTopTokens outside of the useEffect for reusability
  const fetchTopTokens = async () => {
    setError(false);

    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_USE_REMOTE_API === 'true'
          ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/category-daily-scores?category=${category}&videoLimit=300`
          : `${process.env.REACT_APP_AWS_API}/api/category-daily-scores?&category=${category}&videoLimit=300`,
      );
      setCounter(true);

      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: ChartDataPoint[] = await response.json();
      setCategorySwitchData(data);
      setChartData(data);
      
      // // Transform the data for the chart
      // const transformedData: ChartDataPoint[] = data.map(item => ({
      //   date: item.created,
      //   score: item.total_weighted_view_score,
      //   tokens: item.total_mentions
      // }));

    } catch (error) {
      console.error('Error fetching top tokens:', error);
      setError(true);
    } finally {
      setLoading(false);
    }

  };



  if (!categorySwitchData) {
    return null;
  }

  return (
    <>
      <h1>CATEGORY TRENDS</h1>

        <div className="category-select-wrapper">

          <FormControl sx={{ m: 0 }}>
            <Select 
              id="grouped-select"
              label="Grouping"
              displayEmpty
              value={category}
              onChange={(e) => {
                const newCategory = e.target.value;
                setCategory(newCategory);
                setSelectedItem(null);

                if (categoryTrends) {
                  const selectedCategoryData = categoryTrends.gainers.find(item => item.category === newCategory)
                    || categoryTrends.losers.find(item => item.category === newCategory);

                  if (selectedCategoryData?.time_series) {
                    setCurrentChartData(selectedCategoryData.time_series.map(point => ({
                      date: point.date,
                      value: point.score
                    })));
                  }
                }
              }}
            >
              <MenuItem style={{fontSize: '12px'}} value="Select a Trending Category" disabled>
                Select a Trending Category
              </MenuItem>
              <ListSubheader style={{fontSize: '12px'}}>Gainers</ListSubheader>
              {categoryTrends && categoryTrends.gainers.map((item) => (
                <MenuItem style={{fontSize: '12px'}} key={`gainer-${item.category}`} value={item.category}>{item.category}</MenuItem>
              ))}
              <ListSubheader style={{fontSize: '12px'}}>Losers</ListSubheader>
              {categoryTrends && categoryTrends.losers.map((item) => (
                <MenuItem style={{fontSize: '12px'}} key={`loser-${item.category}`} value={item.category}>{item.category}</MenuItem>
              ))}
            </Select>
          </FormControl>

    
        </div>
 

      <ChartAreaSelect
        y1Measuring={'views'}
        height={24}
        width={100}
        data={chartData}
        y1Colour={'#50B8E7'}
        y2Colour={'#50B8E7'}
        showTrendline={false}
      />
    </>
  );
}

export default CategorySwitch;