import React, { ReactNode } from 'react';
import { AreaChart, Tooltip, Area, ResponsiveContainer, TooltipProps, XAxis, YAxis, CartesianGrid, Line } from 'recharts';

const priceLineColour = '#50B8E7';

interface ChartAreaProps {
  height: number;
  width: number;
  marginTop?: number;
  y1Measuring: string;
  y1Colour?: string;
  y2Colour?: string;
  dots?: boolean;
  showTrendline?: boolean;
  data: {
    date: string;
    score: number;
    tokens: number;
  }[];
  headerContent?: ReactNode;
  isMicroTooltip?: boolean;
}

interface Payload {
  score: number;
  tokens: number;
  date: string;
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  isMicroTooltip?: boolean;
  y1Measuring: string;
  y1Colour?: string;
  y2Colour?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  isMicroTooltip,
  y1Measuring,
  y1Colour,
  y2Colour = priceLineColour,
}) => {
  if (active && payload && payload.length) {
    const formattedDate = new Date(label).toLocaleDateString();

    return (
      <div className={`custom-tooltip ${isMicroTooltip ? 'micro-tooltip' : 'normal-tooltip'}`}>
        <div className="intro">
          <span className="color-box" style={{ backgroundColor: y1Colour }}></span>
          {`${(payload[0].payload as Payload).score} ${y1Measuring} on ${formattedDate}`}
        </div>
        {payload[0].payload.tokens !== undefined && (
          <div className="y2">
            <span className="color-box" style={{ backgroundColor: y2Colour }}></span>
            <span>{`Tokens: ${(payload[0].payload as Payload).tokens}`}</span>
          </div>
        )}
      </div>
    );
  }

  return null;
};

const ChartAreaSelect: React.FC<ChartAreaProps> = ({
  data,
  y1Colour,
  isMicroTooltip,
  y1Measuring,
  dots,
  y2Colour = priceLineColour,
}) => {
  const hasTokens = data.some((item) => 'tokens' in item);
  const strokeWidth = 1;

  const fallbackData = [
    { date: new Date().toISOString(), score: 0, tokens: 0 },
    { date: new Date().toISOString(), score: 0, tokens: 0 }
  ];

  const chartData = data.length > 1 ? data : fallbackData;

  // Calculate min and max values for just the data
  const allYValues = data.map(d => d.score);
  const minY = Math.min(...allYValues);
  const maxY = Math.max(...allYValues);

  // Add some padding (10%) to the range
  const yRange = maxY - minY;
  const yPadding = yRange * 0.1;
  const yDomain: [number, number] = [minY - yPadding, maxY + yPadding];

  return (
    <div className="chart-area-compact">
      <ResponsiveContainer className="chart-area-responsive-compact">
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={y2Colour} stopOpacity={1} />
              <stop offset="95%" stopColor={y2Colour} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" hide={true} domain={['dataMin', 'dataMax']} />
          <YAxis
            yAxisId="left"
            hide={true}
            domain={yDomain}
          />
          {hasTokens && <YAxis yAxisId="right" orientation="right" hide={true} domain={['auto', 'auto']} />}
          <Tooltip
            content={
              <CustomTooltip
                isMicroTooltip={isMicroTooltip}
                y1Measuring={y1Measuring}
                y1Colour={y1Colour}
                y2Colour={y2Colour}
              />
            }
            cursor={{ stroke: y1Colour, strokeDasharray: 2, fillOpacity: 0 }}
          />
          <Area
            type="linear"
            dataKey="score"
            stroke={data.length > 1 ? y1Colour : y2Colour}
            strokeWidth={strokeWidth}
            strokeOpacity={data.length > 1 ? 1 : 0.2}
            fillOpacity={1}
            fill="url(#colorPv)"
            yAxisId="left"
            dot={dots ? { stroke: 'white', strokeWidth: strokeWidth, r: 3, strokeDasharray: '' } : { r: 0 }}
            isAnimationActive={true}
            animationDuration={200}
          />

          {hasTokens && (
            <Line
              type="linear"
              dataKey="tokens"
              stroke={y2Colour}
              strokeWidth={strokeWidth}
              yAxisId="right"
              dot={false}
              isAnimationActive={true}
              animationDuration={200}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartAreaSelect;
