import React, { ReactNode } from 'react';
import { AreaChart, Tooltip, Area, ResponsiveContainer, TooltipProps, XAxis, YAxis, CartesianGrid } from 'recharts';
import Currency from '../../components/Currency';
import { displayTimestamp } from '../../utilities/statistical-utils';

const priceLineColour = '#50B8E7';

interface ChartAreaProps {
  height: number;
  width: number;
  marginTop?: number;
  y1Measuring: string;
  y1Colour?: string;
  y2Colour?: string;
  dots?: boolean;
  showTrendline?: boolean;
  data: {
    x: number;
    y: number;
    y2?: number;
  }[];
  headerContent?: ReactNode;
  isMicroTooltip?: boolean;
}

interface Payload {
  y: number;
  y2: number;
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  isMicroTooltip?: boolean;
  y1Measuring: string;
  y1Colour?: string;
  y2Colour?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  isMicroTooltip,
  y1Measuring,
  y1Colour,
  y2Colour = priceLineColour,
}) => {
  if (active && payload && payload.length) {
    const formattedDate = displayTimestamp(label);

    return (
      <div className={`custom-tooltip ${isMicroTooltip ? 'micro-tooltip' : 'normal-tooltip'}`}>
        <div className="intro">
          <span className="color-box" style={{ backgroundColor: y1Colour }}></span>
          {`${(payload[0].payload as Payload).y} ${y1Measuring} on ${formattedDate}`}
        </div>
        {payload[0].payload.y2 !== undefined && (
          <div className="y2">
            <span className="color-box" style={{ backgroundColor: y2Colour }}></span>
            <span>{`when price was `}</span>
            <Currency value={(payload[0].payload as Payload).y2} />
          </div>
        )}
      </div>
    );
  }

  return null;
};

const ChartArea: React.FC<ChartAreaProps> = ({
  data,
  y1Colour,
  isMicroTooltip,
  y1Measuring,
  dots,
  y2Colour = priceLineColour,
  showTrendline = true,
}) => {
  const hasY2 = data.some((item) => 'y2' in item);
  const strokeWidth = 1;
  const strokeOpacity = 1;

  const fallbackData = [
    { x: 0, y: 0 },
    { x: 0, y: 0 }
  ];

  const chartData = data.length > 1 ? data : fallbackData;
  const { trendlinePoints: trendlineData, percentageChange } = data.length > 1
    ? calculateTrendline(data)
    : { trendlinePoints: fallbackData, percentageChange: 0 };

  // Calculate min and max values including both data and trendline
  const allYValues = [...data.map(d => d.y), ...trendlineData.map(d => d.y)];
  const minY = Math.min(...allYValues);
  const maxY = Math.max(...allYValues);

  // Add some padding (10%) to the range
  const yRange = maxY - minY;
  const yPadding = yRange * 0.1;
  const yDomain: [number, number] = [minY - yPadding, maxY + yPadding];

  return (
    <div className="chart-area">
      {showTrendline && <div className={
        percentageChange === 0 ? 'trendline-percentage' :
          percentageChange > 0 ? 'trendline-percentage-up' : 'trendline-percentage-down'
      }>
        {percentageChange.toFixed(0)}%
      </div>
      }
      <ResponsiveContainer className="chart-area-responsive">
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={y2Colour} stopOpacity={1} />
              <stop offset="95%" stopColor={y2Colour} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="x" hide={true} domain={['dataMin', 'dataMax']} />
          <YAxis
            yAxisId="left"
            hide={true}
            domain={yDomain}
          />
          {hasY2 && <YAxis yAxisId="right" orientation="right" hide={true} domain={['auto', 'auto']} />}
          <YAxis 
            yAxisId="trendline"
            hide={true}
            domain={[minY / 2 - yPadding / 2, maxY / 2 + yPadding / 2]}
          />
          <Tooltip
            content={
              <CustomTooltip
                isMicroTooltip={isMicroTooltip}
                y1Measuring={y1Measuring}
                y1Colour={y1Colour}
                y2Colour={y2Colour}
              />
            }
            cursor={{ stroke: y1Colour, strokeDasharray: 2, fillOpacity: 0 }}
          />
          <Area
            type="linear"
            dataKey="y"
            stroke={data.length > 1 ? y1Colour : y2Colour}
            strokeWidth={strokeWidth}
            strokeOpacity={strokeOpacity}
            fillOpacity={1}
            fill="url(#colorPv)"
            yAxisId="left"
            dot={dots ? { stroke: 'white', strokeWidth: strokeWidth, r: 3, strokeDasharray: '' } : { r: 0 }}
            isAnimationActive={true}
            animationDuration={200}
          />

          {data.length > 1 && showTrendline && (
            <Area
              type="linear"
              data={trendlineData.map(point => ({ ...point, y: point.y * 10 }))}
              dataKey="y"
              stroke={
                percentageChange === 0 ? '#FFC32A' :  // orange for flat
                percentageChange > 0 ? '#00A6B2' :    // green for up
                '#f45959'                             // red for down
              }
              strokeWidth={strokeWidth}
              strokeDasharray="5 2"
              strokeOpacity={strokeOpacity}
              fillOpacity={0}
              yAxisId="trendline"
              dot={false}
              isAnimationActive={true}
              animationDuration={200}
              activeDot={false} 
            />
          )}

          {hasY2 && (
            <Area
              type="linear"
              dataKey="y2"
              stroke={y2Colour}
              strokeWidth={strokeWidth}
              fillOpacity={0}
              yAxisId="right"
              isAnimationActive={true}
              animationDuration={200}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

// Add calculateTrendline function
const calculateTrendline = (data: { x: number; y: number }[]) => {
  const n = data.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;

  // Calculate sums
  data.forEach(point => {
    sumX += point.x;
    sumY += point.y;
    sumXY += point.x * point.y;
    sumXX += point.x * point.x;
  });

  // Calculate slope (m) and y-intercept (b)
  const m = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const b = (sumY - m * sumX) / n;

  // Generate trendline points
  const trendlinePoints = data.map(point => ({
    x: point.x,
    y: m * point.x + b
  }));

  const startValue = trendlinePoints[0].y;
  const endValue = trendlinePoints[trendlinePoints.length - 1].y;

  const percentageChange = ((endValue - startValue) / startValue) * 100;

  return { trendlinePoints, percentageChange };
};

export default ChartArea;
