import React from 'react';
import ViewsTrends from '../../modules/ViewsTrends';
import Buzzwords from '../../modules/Buzzwords';
import Versus from '../../modules/Versus';
import CoinsTopDefault from '../../modules/CoinsTopDefault';
import Sentiment from '../../modules/Sentiment';
import Chatter from '../../modules/Chatter';
import Chains from '../../modules/Chains';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Nav from '../../components/Nav';
import { sendGAEvent } from '../../utilities/helpers';

// import { useMyContext } from '../../ContextProvider';

// const promotionStatus = localStorage.getItem('promotionStatus');

const Core: React.FC = () => {
  // const { moduleBuzzwords, compactStatus } = useMyContext();

  // New state for slider value
  // const [sliderValue, setSliderValue] = useState(0);

  // Function to calculate background color based on slider value
  // const calculateColor = (value: number) => {
  //   const colors = ['#000d21', '#121d2d', '#135A90' , '#7473B3', '#50b8e7', '#99aebb', 'white'];
  //   return colors[value];
  // };

  // Effect to update body background color when slider value changes
  // useEffect(() => {
  //   document.body.style.backgroundColor = calculateColor(sliderValue);
  // }, [sliderValue]);

  sendGAEvent("Loaded Dashboard", { dashboard: "Core" });


  return (
    <>
      {/*  slider input for changing background color */}
      {/* <input
            type="range"
            min="0"
            max="8"
            value={sliderValue}
            onChange={(e) => setSliderValue(Number(e.target.value))}
            style={{ position: 'fixed', top: '12px', right: '240px', zIndex: 900000 }}
          /> */}
      <Nav value="" />

      <div className="masonry">

        <Chatter />
        <Chains /> 
        <CoinsTopDefault />

        <ResponsiveMasonry columnsCountBreakPoints={{ 360: 1, 720: 1, 960: 2, 1024: 2, 1280: 3, 1440: 3, 1600: 3, 1800: 3 }}>
          <Masonry columnsCount={3}>

            <Buzzwords />
            <Sentiment />
            <Versus />
            <ViewsTrends />
          </Masonry>
        </ResponsiveMasonry>

        {/* 
        <SponsorAd />
        {/* <DotMatrixGraph /> */}
        {/* <CategoriesTop premium={true} />
            <CoinsNew />
            <CoinsTop />
            {!promotionStatus ? <Promotion /> : <PromotionReset />}
            <ChainsTop />
            <ChannelsTop /> */}

      </div>
    </>
  );
};

export default Core;