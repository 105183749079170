import React from 'react';
import TruncateString from '../../components/TruncateString';

interface TitleProps {
  title: string;
  symbol?: string;
  subtitle?: string;
  chainName?: string;
  label: string;
  effect: boolean;
  titleTruncate?: number;
  logo?: string;
  logoClass?: string;
  left?: string;
  tooltip?: string;
  bottomBumper?: boolean;
  hero?: boolean;
}

const Title: React.FC<TitleProps> = ({
  title,
  subtitle,
  effect,
  symbol,
  left,
  bottomBumper = false,
  titleTruncate = 20,
  logo,
  logoClass,
  tooltip,
  hero = false,
}) => {
  const processedLogo = logo ? logo.replace('/thumb/', '/standard/') : logo;

  return (
    <div className={hero ? 'stat-hero' : 'stat'}>
      <span
        key={title}
        className="title fade-in"
      >
        {processedLogo && processedLogo !== '' ? (
          <img src={processedLogo} alt="" />
        ) : logoClass && logoClass !== '' ? (
          <i id="logo" className={`fa-solid ${logoClass}`}></i>
        ) : null}

        <span id={effect && (processedLogo || logoClass) ? 'effect' : ''}>
         {title} <span>{symbol}</span>
          {/* <TruncateString text={title} maxLength={titleTruncate} />  <span>{symbol}</span> */}
          {subtitle && <small>{subtitle}</small>}
        </span>
      </span>
    </div>
  );
};

export default Title;
