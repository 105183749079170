import React, { useState, useEffect, useMemo } from 'react';

interface TrendItem {
  category: string;
  trend_percentage: number;
  view_trend_percentage: number;
  avg_daily_score: number;
  avg_unique_tokens: number;
  total_score: number;
  days_active: number;
  projectName: string;
}

export interface versusProps {
  gainers: TrendItem[];
  losers: TrendItem[];
}

const getTimestamps = () => {
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const currentTimestamp = Math.floor(Date.now() / 1000);
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const oneMonthAgoTimestamp = Math.floor(oneMonthAgo.getTime() / 1000);

  return {
    currentTimestamp,
    oneMonthAgoTimestamp,
    currentDate: formatDate(new Date()),
    oneMonthAgoDate: formatDate(oneMonthAgo),
  };
};


function Versus() {

  const [versus, setVersus] = useState<versusProps | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {

    const fetchVersus = async () => {
      setLoading(true);
      setError(null);

      // const { currentDate, oneMonthAgoDate } = getTimestamps();

      try {

        const response = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-token-gainers-losers?limit=10&videoLimit=100`
            : `${process.env.REACT_APP_AWS_API}/api/top-token-gainers-losers?limit=10&videoLimit=100`,
        );
        

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // Sort the data before setting state
        const sortedData = {
          gainers: [...data.gainers].sort((a, b) => b.total_score - a.total_score),
          losers: [...data.losers].sort((a, b) => b.total_score - a.total_score)
        };
        setVersus(sortedData);

      } catch (error) {
        console.error('Error fetching versus data:', error);
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(String(error));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchVersus();
  }, []);

  const renderGainersList = React.useMemo(() => {
    if (!versus?.gainers || !versus?.losers) return null;

    const sortedGainers = [...versus.gainers].sort((a, b) => b.total_score - a.total_score);

    return sortedGainers.map((item: TrendItem, index: number) => {
      return (
        <div key={index} className='meter-versus-up'>
          <span className='meter-versus-up-note'>{item.projectName}
            <strong>{item.view_trend_percentage.toFixed(0)}%</strong>
          </span>
          <span className="meter-versus-up-bar" style={{ width: `${item.view_trend_percentage.toFixed(0)}%` }}>
          </span>
        </div>
      );
    });
  }, [versus?.gainers, versus?.losers]);


  const renderLosersList = React.useMemo(() => {
    if (!versus?.gainers || !versus?.losers) return null;

    const sortedLosers = [...versus.losers].sort((a, b) => b.total_score - a.total_score);

    return sortedLosers.map((item: TrendItem, index: number) => {
      return (
        <div key={index} className='meter-versus-down'>
          <span className='meter-versus-down-note'><strong>{item.view_trend_percentage.toFixed(0)}%</strong> {item.projectName}
          </span>
          
          <span className="meter-versus-down-bar" style={{ width: `${Math.abs(item.view_trend_percentage)}%` }}>
          </span>
        </div>
      );
    });
  }, [versus?.gainers, versus?.losers]);



  // Add loading state display
  if (!versus) {
    return null;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <>
      <div className='general-container'>


        {!loading && <div className="toggle-container">WINNERS VERSUS LOSERS</div>}

        <div className="general-data-mini">
          {loading ?
            <div className="loading">
              <i className="fa-sharp-duotone fa-solid fa-spinner-third"></i>
            </div>
            :
            <>
              <div className="box">
                <div className="inner">
                  <span>LOSERS</span>
                </div>
                <div className="inner">
                  <span>GAINERS</span>
                </div>
              </div>
              <div className="versus-container">


                <div className='versus-box-left'>
                  {/* <div className='versus-box-left-top'>
                    LOSERS
                  </div> */}
                  <div className='versus-box-left-bottom'>
                    {renderLosersList}
                  </div>
                </div>

                <div className='versus-box-right'>
                  {/* <div className='versus-box-right-top'>
                    GAINERS
                  </div> */}
                  <div className='versus-box-right-bottom'>
                    {renderGainersList}
                  </div>
                </div>

              </div>
            </>
          }
        </div>
      </div>
    </>
  );
}

export default Versus;
