import { useState, useEffect, useMemo, useCallback } from 'react';
import Typewriter from "typewriter-effect";

import CountUp from 'react-countup';
import CategorySwitch from '../CategorySwitch';

export interface ChatterProps {
  averageConvictionPercentage: number;
  percentageChange: number;
  positiveConvictionPercentage: number;
  negativeConvictionPercentage: number;
  mixedConvictionPercentage: number;
  cryptoProjects: string[];
  projectName: string;
  tokenSymbol: string;
  chainName: string;
  logo: string;
  total_mentions: number;
  total_weighted_view_score: number;
  channelCount: number;
}

export interface PendingProps {
  videoId: string;
  channelName: string;
  videoLink: string;
  title: string;
  views: number;
  cryptoProjects: string[];
}

export interface ServerStatsProps {
  channelName: string;
  dailyVideoSeries: [
    {
      date: string;
      count: number
    }
  ],
  cronJobStatus: {
    videoScanner: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      scannerRunning: false;
    },
    githubPush: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      pushRunning: boolean;
    },
    tokenPopulate: {
      isRunning: boolean;
      isExecuting: boolean;
      schedulerExists: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      currentInterval: number;
      isInitialized: boolean;
    }
  },
  videoStats: {
    latestPendingVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestScannedVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
      status: string;
    };
    totalVideosAllTime: number;
    totalVideosInTimespan: number;
    uniqueChannelsInTimespan: number;
  },
  tokenStats: {
    latestCalls: [
      {
        tokenId: string,
        videoId: string,
        datetime: string,
        projectName: string,
        tokenSymbol: string
      }],
    latestSentiment: {
      videoId: string,
      datetime: string,
      market_sentiment: string,
      conviction: number
    }
  };
}

function Chatter({ title = 'Chatter' }: { title?: string }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [serverStatusData, setServerStatusData] = useState<ServerStatsProps | null>(null);
  const [topTokens, setTopTokens] = useState<any[] | null>(null);

  const getTimestamps = useCallback(() => {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return {
      currentDate: formatDate(new Date()),
      oneMonthAgoDate: formatDate(oneMonthAgo),
    };
  }, []);

    const fetchTopTokens = async () => {
      const fetchWithLimit = async (limit: number) => {
        const response = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-new-tokens?interval=daily&videoLimit=${limit}`
            : `${process.env.REACT_APP_AWS_API}/api/top-new-tokens?interval=daily&videoLimit=${limit}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      };

      try {
        let videoLimit = 50;
        const maxLimit = 500; 
        let data;

        do {
          data = await fetchWithLimit(videoLimit);
          if (!data[0] && videoLimit < maxLimit) {
            videoLimit += 50;
            console.log(`No data found, increasing limit to ${videoLimit}`);
          }
        } while (!data[0] && videoLimit < maxLimit);

        console.log("fetchTopTokens", data[0]);
        setTopTokens(data);
      } catch (error) {
        console.error('Error fetching tokens:', error);
        if (error instanceof Error) {
          console.log(error.message);
        } else {
          console.log(error);
        }
      }
    };



  const fetchScannerStatus = useCallback(async () => {
    setError(false);
    const { currentDate, oneMonthAgoDate } = getTimestamps();

    try {
      setCounter(false);
      const responseServerStatus = await fetch(
        process.env.REACT_APP_USE_REMOTE_API === 'true'
          ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/scanner-status?startDate=${oneMonthAgoDate}&endDate=${currentDate}`
          : `${process.env.REACT_APP_AWS_API}/api/scanner-status?startDate=${oneMonthAgoDate}&endDate=${currentDate}`,
      );

      if (!responseServerStatus.ok) {
        throw new Error(`HTTP error! status: ${responseServerStatus.status}`);
      }

      const dataServerStatus: ServerStatsProps = await responseServerStatus.json();
      setServerStatusData(dataServerStatus);
      setLoading(false);
      setCounter(true);
    } catch (error) {
      console.error('Error fetching SERVER STATUS:', error);
      setError(true);
    }
  }, [getTimestamps]);

  useEffect(() => {
    fetchScannerStatus();
    fetchTopTokens();

    const interval = setInterval(fetchScannerStatus, 30000);
    return () => clearInterval(interval);
  }, [fetchScannerStatus]);

  const latestPendingVideo = useMemo(() => serverStatusData?.videoStats.latestPendingVideo, [serverStatusData]);
  const latestScannedVideo = useMemo(() => serverStatusData?.videoStats.latestScannedVideo, [serverStatusData]);
  const dailyVideoSeriesCount = useMemo(() => serverStatusData?.dailyVideoSeries[0]?.count, [serverStatusData]);
  const totalVideosAllTime = useMemo(() => serverStatusData?.videoStats.totalVideosAllTime, [serverStatusData]);

  if (!serverStatusData) {
    return null;
  }

  return (
    <>
      <div className="general-data-activity">
        {loading ? (
          <div className="loading">
            <i className="fa-sharp-duotone fa-solid fa-spinner-third"></i>
          </div>
        ) : (

          <>

            <div className='activity-container'>

              <div className='activity-feed-standard'>
                <div className='activity-readout'>
                  <div className="activity-readout-top">
                    <Typewriter
                      options={{
                        autoStart: true,
                        loop: true,
                        cursor: "",
                        delay: 12,
                        deleteSpeed: 0,
                        skipAddStyles: true, // Improves performance
                      }}
                      onInit={(typewriter) => {
                        typewriter
                          .pauseFor(360)
                          .typeString(`<h1>Currently Scanning</h1>`)
                          .typeString(`${latestPendingVideo?.title}`)
                          .pauseFor(360)
                          .typeString(`<a href='https://www.youtube.com/@${latestPendingVideo?.channelName}' target='_blank' rel='noopener noreferrer'><i class='fa-brands fa-youtube'></i> ${latestPendingVideo?.channelName}</a>`)
                          .typeString(`<div class='progress'></div>`)
                          .pauseFor(9000)
                          .deleteAll(0)
                          .pauseFor(360)
                          .typeString(`<h1>Latest Completed Scan</h1>`)
                          .typeString(`${latestScannedVideo?.title}`)
                          .pauseFor(360)
                          .typeString(`<a href='https://www.youtube.com/@${latestScannedVideo?.channelName}' target='_blank' rel='noopener noreferrer'><i class='fa-brands fa-youtube'></i> ${latestScannedVideo?.channelName}</a>`)
                          .typeString(`<div class='progress'></div>`)
                          .pauseFor(9000)
                          .deleteAll(0)
                          .pauseFor(360)
                          .start();
                      }}
                    />
                  </div>

                  <div className='activity-readout-bottom'>
                    <h1 style={{ textAlign: "right", padding: "0", margin: "0" }}>
                      <Typewriter
                        options={{
                          autoStart: true,
                          loop: true,
                          cursor: "_",
                          delay: 24,
                          deleteSpeed: 0,
                        }}
                        onInit={(typewriter) => {
                          typewriter
                            .pauseFor(2400)
                            .typeString(`<strong>${dailyVideoSeriesCount || 0}</strong> <span>Sources Scanned Today</span>`)
                            .pauseFor(6000)
                            .deleteAll(0)
                            .pauseFor(360)
                            .typeString(`<strong>${totalVideosAllTime || 0}</strong> <span>Total Scans</span>`)
                            .pauseFor(6000)
                            .deleteAll(0)
                            .start();
                        }}
                      />
                    </h1>
                  </div>
                </div>
              </div>


              <div className="activity-feed-single">
                <CategorySwitch />
              </div>


            </div>


          </>
        )}
      </div>

      <div className="api-counter">

        {counter ? (
          <>
            DATA CHECK
            <strong>
              <CountUp
                start={30}
                end={0}
                duration={30}
                useEasing={false}
                delay={0}
                decimals={1}
              />
            </strong>
          </>
        ) : "UPDATING"}
      </div>
    </>
  );
}

export default Chatter;